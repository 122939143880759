<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="!leadid">
          Add Lead
        </h1>
        <h1 v-if="leadid">
          Edit Lead
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="leads()"
        >
          Leads
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!leadid"
          title="Lead Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="leadid"
          title="Lead Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>
  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :data="tableData"      
    :label-position="labelPosition" 
  >
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Name"
          prop="sender_name"
        >
          <el-input
            v-model="data.sender_name"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Email"
          prop="sender_email"
        >
          <el-input
            v-model="data.sender_email"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >    
        <el-form-item
          label="Phone"
          prop="sender_phone"
        >
          <el-input
            v-model="data.sender_phone"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Source"
          prop="source"
        >
          <el-input
            v-model="data.source"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >    
        <el-form-item
          label="Message"
          prop="message"
        >
        <TinyMce
              v-if="loadEditor"
              v-bind:content="data.message"
              @contentChanged="updateTextAreaValue"
          >
          </TinyMce>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      > 
        <el-form-item>
          <input
            id="leadid"
            v-if="leadid"
            v-model="leadid"
            name="leadid"
            type="hidden"
          >
          <input
            id="customer_id"
            v-if="data.customer_id"
            v-model="data.customer_id"
            name="customer_id"
            type="hidden"
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            Submit
          </el-button>
          <el-button  v-if="!leadid" @click="resetForm('ruleForm')">
            Reset
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import LeadsService from "@/api/leads.service";
import UserService from "@/api/user.service";
import TinyMce from "@/components/TinyMce"
  export default {
       components: {
      TinyMce
    },
    data() {
      var checkSenderName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Sender Name.'));
        } else {
          callback();
        }
      };
      var checkSenderEmail = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Email Name.'));
        } else {
          callback();
        }
      };
      var checkMessage = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Message.'));
        } else {
          callback();
        }
      };
      var checkMetatitle = (rule, value, callback) => {        
          callback();
      };
      return {
        showAlertAdded: false,
        labelPosition:"top",
        leadid: this.$route.params.leadid,
        data: {
          sender_name: null,
          sender_email: null,
          sender_phone:null,
          customer_id:null,
          source:'Admin',
          message: null
        },
        rules: {
          sender_name: [
            { validator: checkSenderName, trigger: 'blur' }
          ],
          sender_email: [
            { validator: checkSenderEmail, trigger: 'blur' }
          ],
          message: [
            { validator: checkMessage, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {
    if(this.leadid){
      this.getlead();
    }else{
      this.getuser();
    }
  },
   computed: {
      loadEditor: function () {
        return !this.leadid || (this.leadid && this.data.message);
        },
  },
    methods: {
      updateTextAreaValue(value) {
        this.data.message = value;
      },
      submitForm(formNameLead) {
        this.$refs[formNameLead].validate((valid) => {
          if (valid && !this.leadid) {
             return LeadsService.add(this.data).then(response => {
                this.showAlertAdded=true 
                return response
              });
          } else if (valid && this.leadid) {
            return LeadsService.update(this.leadid, this.data).then(response => {
              this.showAlertAdded=true
              return response
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formNameLead) {
        this.$refs[formNameLead].resetFields();
      },
	leads(){
		this.$router.push("/leads");
	},
  getuser(){
    return UserService.getUserBoard().then(response => {  
        this.data.sender_name  =  response.data.name;
        this.data.sender_phone =  response.data.phone;
        this.data.sender_email = response.data.email;
        this.data.customer_id = response.data.id;
        
      });
  },
	getlead(){
    return LeadsService.get(this.leadid).then(response => {
        this.data = response.data.data;
      });
	}
    }
  }
</script>

<style  lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}

    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
     div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>